import { ComponentManager } from "./modules/componentManager.min.js";
import { ProjectsPaginator } from "./modules/projectsPaginator.min.js";
import { ContactFormManager } from "./modules/contactForm.min.js";
import { LazyLoad } from "./modules/lazy-load.min.js";
import { Swipers } from "./modules/swipers.min.js";

let appLenis = {};
let swup = null;

// Initialize page
function initPage() {
  // Initialize Gsap + ScrollTrigger
  gsap.registerPlugin(ScrollTrigger);

  // Initialize Swup
  swup = new Swup({
    containers: ["#swup", "#site-navbar"],
    cache: false,

    // Swup theme
    plugins: [
      new SwupSlideTheme({
        reversed: true,
      }),
    ],
  });

  // Init transition callback
  transitionCallback();

  // Set transition callback
  swup.hooks.on("content:replace", transitionCallback);

  // Reload iubenda iframes
  swup.hooks.on("page:view", function() {
    if ($(".iubenda-embed").length > 0) {
      location.reload();
    }
  });
}

function transitionCallback() {
  // Destroy prvious animations
  destroyAnimations();

  // Stop lenis to prevent scrolling during transitions
  if (appLenis.initialized) {
    appLenis.destroy();
  }

  // Initialize Lenis
  initLenis();

  // Initialize swipers
  new Swipers().init();

  // Initialiaze some components
  new ComponentManager().refreshComponents();

  // Projects page
  new ProjectsPaginator().initPaginator();

  // Init contact form if exists
  new ContactFormManager().init();

  // Initialize lazy load
  new LazyLoad().init();

  // Iubenda check
  iubendaCheck();

  // Hide/Show reCaptcha badge based on page
  if ($("#contact-form").length > 0) {
    $(".grecaptcha-badge").addClass("visible");
  } else {
    $(".grecaptcha-badge").removeClass("visible");
  }

  // Check if page is home
  if (window.location.pathname == "/") {
    $(".navbar").addClass("animate-navbar");
  }
}

function initLenis() {
  appLenis = {
    initialized: false,
    _lenis: null,

    init() {
      this._lenis = new Lenis({
        lerp: 0.085,

        // Prevent scrolling over services component
        prevent: (node) => node.classList.contains("departments-overview"),
      });

      this.initEvents();
      this.initialized = true;
    },

    initEvents() {
      this._lenis.on("scroll", ScrollTrigger.update);

      gsap.ticker.add((time) => {
        this._lenis.raf(time * 1000);
      });
    },

    destroy() {
      this._lenis.stop();
      this._lenis.destroy();
    },
  };

  appLenis.init();
}

function destroyAnimations() {
  const Alltrigger = ScrollTrigger.getAll();
  for (let i = 0; i < Alltrigger.length; i++) {
    Alltrigger[i].kill(true);
  }
}

function iubendaCheck() {
  // Remove iubenda cookie message
  setTimeout(function() {
    if (_iub.cs.consent.purposes !== undefined) {
      let purposes_iub = Object.keys(_iub.cs.consent.purposes);

      for (let i = 0; i < purposes_iub.length; i++) {
        if (_iub.cs.consent.purposes[purposes_iub[i]] == true) {
          // Hide cookie message for enabled purposes
          $(".content-before-consent-" + purposes_iub[i]).each(
            function(_, el) {
              el.style.display = "none";
            },
          );

          // Send events to GTM
          switch (purposes_iub[i]) {
            case "2":
              // Attivatore Tag Manager categoria Interazioni Semplici
              dataLayer.push({
                event: "iubenda-interazioni-semplici",
              });
              break;
            case "3":
              // Attivatore Tag Manager categoria Esperienza Migliorata
              dataLayer.push({
                event: "iubenda-esperienza-migliorata",
              });
              break;
            case "4":
              // Attivatore Tag Manager categoria Misurazione
              dataLayer.push({ event: "iubenda-misurazione" });
              break;
            case "5":
              // Attivatore Tag Manager categoria Pubblicità
              dataLayer.push({ event: "iubenda-pubblicita" });
              break;
          }
        } else {
          // Show cookie message for disabled purposes
          $(".content-before-consent-" + purposes_iub[i]).each(
            function(_, el) {
              el.style.display = "flex";
            },
          );
        }
      }
    } else {
      // Show cookie message for disabled purposes
      $(".content-before-consent").each(function(_, el) {
        el.style.display = "block";
      });
    }
    _iub.cs.api.activateSnippets();
  }, 500);
}

function removePreloader() {
  // Remove preloader
  $(".preloader").fadeOut(500);
}

$(window).on("load", function() {
  initPage();
  removePreloader();
});
